// Do not edit this file, it is automatically generated
const locales = {
  "pt": {
    "translation": {
      "app_name": {
        "asset_inventory": "Inventário de Ativos",
        "risk_register": "Registro de riscos",
        "default": "Inventário de objetos"
      },
      "activities_page": {
        "activities": "Atividades",
        "generate_activity_log": "Gerar log de atividades",
        "bubbly_message": "Gere um log contendo as atividades da sua organização no Inventário de Ativos e no registro de riscos no último mês.\nEnviaremos um e-mail com um link para que você baixe o log de atividades.\n",
        "bubbly_warning": "O link para download expirará 30 minutos após o recebimento do e-mail.",
        "success_toast_message": "Sua exportação começou. Enviaremos um e-mail com um link para o arquivo de log de atividades quando ele ficar pronto.\nO processo de exportação pode demorar um pouco quando o arquivo contém um grande número de atividades.\n",
        "export_activity_log": "Exportar log de atividades"
      },
      "activity_log": "Atividade",
      "object_types": {
        "asset": "Ativo",
        "record": "Registro"
      },
      "breadcrumbs": "Navegação estrutural",
      "links": {
        "support_url": "https://community.wegalvanize.com/s/contactsupport?language=pt_br",
        "empty_assessments_help_url": "https://help.highbond.com/helpdocs/highbond/pt-br/Default.htm#cshid=pm-asset-workflows"
      },
      "no_results": {
        "heading": "Nenhum Resultado Encontrado",
        "body": "Refine sua pesquisa ou selecione um filtro diferente."
      },
      "empty_results": {
        "heading": "Nada aqui ainda!",
        "body": "Adicione {{- recordTypeName}} para criar um novo registro para este(a) {{- parentName}}."
      },
      "error_notification": {
        "heading": "Ocorreu um problema",
        "body": "Algo deu errado. Atualize a página e tente novamente. Se o problema persistir, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">entre em contato com nossa equipe de suporte</a>."
      },
      "no_access_notification": {
        "heading": "Sem Acesso",
        "body": "Você não tem acesso a estes dados. Tente falar com um administrador do HighBond em sua organização para ver se você deve ter acesso."
      },
      "asset_types_page": {
        "page_title": {
          "asset_inventory": "Inventário de Ativos - HighBond",
          "risk_register": "Registro de riscos - HighBond",
          "default": "Inventário de objetos - HighBond"
        },
        "heading": {
          "asset_inventory": "Inventário de Ativos",
          "risk_register": "Registro de riscos",
          "default": "Inventário de objetos"
        },
        "asset_type": {
          "asset_inventory": "Tipo de ativo",
          "default": "Tipo"
        },
        "description": "Descrição"
      },
      "assets_index_page": {
        "page_title": {
          "asset_inventory": "{{- assetTypeName}} - Inventário de Ativos",
          "risk_register": "{{- assetTypeName}} - Registro de riscos",
          "default": "{{- assetTypeName}} - Inventário de objetos"
        },
        "not_configured": {
          "heading": "{{- appTitle}} não configurado",
          "message": "{{- appTitle}} ainda não está configurado em sua organização. Entre em contato com o administrador do sistema."
        }
      },
      "details_page": {
        "heading": "Detalhes de {{- assetTypeName}}",
        "page_title": {
          "asset_inventory": "{{- assetName}} - Inventário de Ativos",
          "risk_register": "{{- assetTypeName}} - Registro de riscos",
          "default": "{{- assetTypeName}} - Inventário de objetos"
        },
        "error": "Algo deu errado. Atualize a página e tente novamente. Se o problema persistir, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">entre em contato com nossa equipe de suporte</a>.",
        "workflow_status": {
          "status_transition_errors": "Ocorreu um problema ao salvar {{- name}}:",
          "status_transition_errors_plural": "Ocorreram alguns problemas ao salvar {{- name}}:",
          "updated_by": "por {{userName}}"
        },
        "add_record": "Adicionar {{- recordTypeName}}",
        "overview": "Visão geral",
        "details": "Detalhes",
        "loading": "Carregando...",
        "number_of_attributes": "{{attributesCount}} atributos",
        "number_of_required_attributes": "{{attributesCount}} atributos obrigatórios",
        "questionnaires": {
          "no_results": {
            "heading": "Procurando Avaliações?",
            "body": "Você pode começar uma avaliação usando os controles de fluxo de trabalho, na parte superior da tela. <a href='{{supportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">Saiba mais</a>"
          },
          "side_panel": {
            "header": "Enviar questionário",
            "select_questionnaire_label": "Questionário",
            "select_questionnaire_placeholder": "Selecione um questionário...",
            "select_email_label": "Destinatário",
            "contact_instructions": "Informe um e-mail personalizado ou selecione um(a) usuário(a) existente para receber o questionário.",
            "invalid_email": "Insira um endereço de e-mail válido.",
            "asset_type_validation": {
              "asset_inventory": "Este ativo não foi configurado para questionários de avaliação. Entre em contato com um administrador para vincular questionários de avaliação a este tipo de ativo.",
              "default": "Este objeto não foi configurado para questionários de avaliação. Entre em contato com um administrador para vincular questionários de avaliação a este tipo."
            },
            "contact_custom_text": "Usar como endereço de e-mail personalizado",
            "contact_custom_instructions": "Procurar usuário ou inserir um endereço de e-mail personalizado",
            "search_placeholder": "Pesquisar..."
          }
        },
        "section_header": {
          "additional_information": "Informações adicionais",
          "number_of_errors": {
            "singular": "{{numberOfErrors}} erro",
            "plural": "{{numberOfErrors}} erros"
          },
          "required_attributes_missing": "{{numberOfPresentAttributes}} de {{numberOfRequiredAttributes}} obrigatórios"
        }
      },
      "actions": {
        "actions": "Ações",
        "send": "Enviar",
        "apply": "Aplicar",
        "close": "Fechar",
        "save": "Salvar",
        "cancel": "Cancelar",
        "decline": "Recusar",
        "approve": "Aprovar",
        "save_changes": "Salvar alterações",
        "discard_changes": "Descartar alterações",
        "add_asset": "Adicionar {{- assetTypeName}}",
        "delete": "Excluir",
        "delete_asset": {
          "label": "Excluir",
          "heading": "Excluir {{- assetName}}?",
          "confirmation": {
            "asset_inventory": "Isso excluirá permanentemente o(a) {{- assetName}} e qualquer trabalho associado, incluindo perfil de ativo, avaliações e registros relacionados. Você não poderá acessar nem restaurar nenhum desses itens.",
            "default": "Isso excluirá permanentemente o(a) {{- assetName}} e qualquer trabalho associado, incluindo seu perfil de ativo, suas avaliações e seus registros relacionados. Você não poderá acessar nem restaurar nenhum desses itens."
          }
        },
        "delete_record": {
          "label": "Excluir",
          "heading": "Excluir {{- recordName}}?",
          "confirmation": "Tem certeza de que deseja excluir {{- recordName}}?  Isso excluirá todo trabalho associado, inclusive seus registros relacionados. Você não poderá acessar nem restaurar nenhum desses itens."
        },
        "download_report": "Baixar relatório",
        "download": "Baixar",
        "sort": {
          "ascending": "Ordenação crescente",
          "descending": "Ordenação decrescente"
        },
        "data_header_menu_trigger": {
          "hide_column": "Ocultar coluna",
          "direction": {
            "ascending": "crescente",
            "descending": "decrescente"
          },
          "with_direction": "Coluna {{- columnName}}, classificada em ordem {{- sortDirection}}, mais ações.",
          "without_direction": "Coluna {{- columnName}}, mais ações.",
          "sort_column_ascending": "Classificar {{- columnName}} em ordem crescente.",
          "sort_column_descending": "Classificar {{- columnName}} em ordem descrescente."
        },
        "done": "Concluído"
      },
      "data_types": {
        "boolean": {
          "true": "Verdadeiro",
          "false": "Falso"
        },
        "datetime": {
          "date": "Data",
          "time": "Tempo"
        },
        "ad_hoc_relationship": {
          "cancel": "Cancelar",
          "connection_type": "Tipo de conexão",
          "link": "Vincular",
          "unlink": "Desvincular",
          "search_display_name": "Filtrar {{displayName}}",
          "add": "Adicionar {{label}}",
          "target_object_display_title": "ID {{- customerObjectId}} - {{- customerObjectName}}",
          "search_issue": "Pesquisar...",
          "no_results": "Nenhum resultado encontrado"
        },
        "attachment": {
          "add": "Adicionar {{attributeTypeName}}",
          "remove": "Remover anexo {{fileName}}?",
          "general_error": "Não foi possível carregar os anexos. Atualize a página e tente novamente. Se o problema persistir, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">entre em contato com nossa equipe de suporte</a>.",
          "no_access_notification": "Você não tem acesso a esses anexos. Tente falar com um administrador do HighBond em sua organização para ver se você deve ter acesso.",
          "asset_grid_label": "Arquivos",
          "grid": {
            "id": "Nome",
            "last_modified": "Carregado(a)",
            "modified_by": "Carregado(a) por",
            "actions": "Ações",
            "download_file": "Baixar {{fileName}}?",
            "file_infected": "Não é possível baixar o arquivo {{fileName}} porque ele está infectado.",
            "file_pending": "Não é possível baixar o arquivo {{fileName}} porque ele está sendo analisado para detecção de malware.",
            "preparing_download": "Preparando download",
            "error_getting_download_url": "Não é possível baixar este arquivo. Tente novamente ou entre em contato com o administrador do sistema para resolver o problema."
          },
          "loading": "Carregando...",
          "add_modal": {
            "heading": "Adicionar novo {{attributeTypeName}}",
            "tooltip_content": "Por motivos de segurança, o HighBond não aceita anexos de arquivo com as seguintes extensões: .bat, .com, ou .exe.\n",
            "label": "Carregar novo {{attributeTypeName}}",
            "error_message_for_reaching_maximum_number": "Número máximo de arquivos alcançado. Exclua o arquivo existente e tente novamente.",
            "error_message_for_reaching_1": "Apenas um arquivo pode ser adicionado a este tipo de atributo. Exclua o arquivo existente e tente novamente.",
            "error_message_for_invalid_extension": "A extensão de arquivo deste anexo não é permitida. Tente uma extensão de arquivo diferente.",
            "error_message_for_duplicated_filename": "Este arquivo já foi carregado como anexo do atributo.",
            "error_message_for_general_error": "Falha ao adicionar anexo. Tente novamente ou entre em contato com o administrador do sistema para resolver o problema."
          }
        }
      },
      "global_notification": {
        "customer_object_created": {
          "success": "{{- customerObjectTypeName}} \"{{- customerObjectName}}\" foi adicionado(a) com sucesso."
        },
        "asset_updated": {
          "success": "Você atualizou um(a) {{- assetTypeName}}!",
          "conflict": {
            "title": "Salvar conflito",
            "description": "Suas alterações estão em conflito com as realizadas atualmente por outro usuário.\nPara salvar suas alterações, copie os dados, atualize a página e reenvie as alterações\n"
          }
        },
        "asset_deleted": {
          "success": "{{- assetTypeName}} \"{{- assetName}}\" excluído(a) com sucesso.",
          "error": "Falha ao excluir \"{{- assetName}}\"."
        },
        "go_to": "Ir para \"{{- assetName}}\"",
        "record_updated": {
          "success": "Você atualizou um registro com sucesso!"
        },
        "questionnaire_sent": {
          "success": "O questionário foi enviado."
        }
      },
      "side_navigation": {
        "objects_heading": {
          "asset_inventory": "Ativos",
          "default": "Objetos"
        },
        "records_heading": "Registros"
      },
      "error_page": {
        "page_title": {
          "asset_inventory": "{{heading}} - Inventário de Ativos",
          "risk_register": "{{heading}} - Registro de riscos",
          "default": "{{heading}} - Inventário de objetos"
        },
        "page_not_found": {
          "heading": "Página não encontrada",
          "body": "Não foi possível encontrar a página que você estava procurando. Você pode retornar para <a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">página inicial do nosso sistema</a> <a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\"> ou </a> entrar em contato com nossa equipe de suporte para obter mais assistência."
        },
        "access_not_allowed": {
          "heading": "Acesso não permitido",
          "body": "De acordo com nossos registros, você não tem permissão para acessar esta página. Tente falar com um administrador do HighBond em sua organização para ver se você deve ter acesso. Como alternativa, você pode retornar para <a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">página inicial do sistema</a> ou <a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\"> entrar em contato com nossa equipe de suporte</a>  para obter mais assistência."
        },
        "footer": "Para obter mais assistência, <a href='{{visitOurSupportPage}}' rel=\"noopener noreferrer\" target=\"_blank\"> visite nossa página de suporte</a> para registrar um tíquete ou conversar on-line com um representante."
      },
      "add_asset_modal": {
        "unsaved_changes_warning": "Você fez alterações que serão perdidas caso feche este formulário.",
        "add_new_item": "Adicionar",
        "name_is_required": "O nome é obrigatório.",
        "item_type": "Tipo de item",
        "name": "Nome"
      },
      "dirty_form": {
        "title": "Este(a) {{- objectTypeName}} tem alterações não salvas.",
        "missing_name_error": "Este(a) {{- objectTypeName}} não pode ser salvo(a) enquanto \"nome\" estiver em branco.",
        "description": "Aparentemente, você estava fazendo atualizações. Se você continuar, perderá todas as alterações não salvas.",
        "save_and_continue": "Salvar e continuar",
        "discard_and_continue": "Continuar sem salvar",
        "cancel": "Cancelar"
      },
      "impact_reports_modal": {
        "download_report": {
          "title": "Baixar relatório",
          "can_be_downloaded": "Os relatórios podem ser baixados nos seguintes formatos.",
          "ready_to_download": "O relatório está pronto para download."
        },
        "just_a_moment": {
          "title": "Aguarde um momento!",
          "description": "Estamos preparando o seu relatório. Pode levar vários minutos, dependendo do tamanho do relatório. O botão de download ficará disponível quando o relatório estiver pronto."
        },
        "format_selection": {
          "label": "Formatos de relatório",
          "instructions": "Selecione um formato de relatório"
        },
        "docx_format": "Microsoft Word",
        "xlsx_format": "Microsoft Excel",
        "pptx_format": "Microsoft PowerPoint",
        "pdf_format": "PDF",
        "no_reports": {
          "title": "Nenhum relatório encontrado.",
          "description": "No momento, não há relatórios disponíveis para download."
        }
      },
      "errors": {
        "handlers": {
          "required_fields_missing": "{{attributeNameComponent}} é obrigatório.",
          "required_relationships_missing": {
            "asset_inventory": "{{attributeNameComponent}} deve estar vinculado(a) ao menos a um ativo ou registro de ativo.",
            "default": "{{attributeNameComponent}} deve estar vinculado(a) ao menos a um objeto."
          },
          "status_not_found": "O status do destino ({{statusId}}) não está no fluxo de trabalho do status atual.",
          "questionnaire_not_found": "O questionário não foi encontrado em Handlers.",
          "failed_to_update_status": "Não é possível atualizar o status do fluxo de trabalho. Atualize a página e tente novamente. Se o problema persistir, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">entre em contato com nossa equipe de suporte</a>.",
          "failed_to_send_questionnaire": "Não é possível enviar o questionário de avaliação. Atualize a página e tente novamente. Se o problema persistir, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">entre em contato com nossa equipe de suporte</a>.",
          "failed_to_publish_protobuf": "Não é possível executar a ação \"notify\" ou \"runRobot\". Atualize a página e tente novamente. Se o problema persistir, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">entre em contato com nossa equipe de suporte</a>."
        },
        "form": {
          "missing_field_value": "Este campo não pode ficar em branco.",
          "missing_relationship_value": {
            "asset_inventory": "O campo deve estar vinculado ao menos a um ativo ou registro de ativo.",
            "default": "O campo deve estar vinculado ao menos a um objeto."
          },
          "invalid_field_value": "O campo tem um valor inválido."
        },
        "configuration": {
          "section_missing_attribute_error": "Antes de continuar, é necessário preencher alguns atributos não visíveis para você. Entre em contato com seu administrador para revisar a configuração desse(a) {{- customerObjectTypeName}}.\n"
        }
      },
      "metadata": {
        "record_id": "ID do registro",
        "position": "Posição",
        "priority": "Prioridade",
        "status": "Status",
        "publish_date": "Data de Publicação",
        "publisher": "Editor",
        "assignee": "Atribuído(a) a",
        "group": "Grupo",
        "updated_at": "Atualizado às",
        "closed_at": "Fechado às",
        "object_type": "Tipo de Objeto",
        "object_id": "ID do Objetivo",
        "object_container_type": "Tipo de Contêiner de Objeto",
        "object_container_id": "ID de Contêiner de Objeto"
      },
      "protected": {
        "source": "Origem",
        "user_id": "ID de Usuário",
        "modified_by": "Modificado(a) Por",
        "status_id": "ID do Status",
        "priority_id": "ID da Prioridade",
        "group_id": "ID do Grupo",
        "external_source": "Fonte Externa",
        "external_type": "Tipo Externo",
        "external_id": "ID Externa",
        "questionnaire_context": "Contexto do Questionário"
      }
    }
  }
};
export default locales;