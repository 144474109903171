const isLocal = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
const protocol = isLocal ? 'http' : 'https';
const [, subdomain, domain, tld] = window.location.hostname.split('.');
const region = process.env.REACT_APP_REGION !== 'us' ? `-${process.env.REACT_APP_REGION}` : '';
const appRegion = process.env.REACT_APP_REGION;
const apiProxyRegionalUrl = process.env.REACT_APP_API_PROXY_REGIONAL_URL;
const host = isLocal ? process.env.REACT_APP_ROOT_HOST : `${domain}.${tld}`;
const isScoped = host === 'highbond-s3.com' && subdomain !== 'asset-inventory';
const apiSubDomain = isScoped
  ? `assets-inventory-${subdomain.split('asset-inventory-').pop()}-api`
  : 'assets-inventory-external';
const isGovCloud = window.location.hostname.includes('highbond-gov');
const isProductionUS = host === 'highbond.com' && appRegion === 'us';

export const Flippers = {
  textEditor: true,
  activities: true,
  impactReports: false,
};

if (['development', 'staging'].includes(process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT as string)) {
  Flippers.activities = true;
  Flippers.impactReports = true;
}

const AppConfig = {
  assetsInventoryApiSessionUrl: `${protocol}://${apiSubDomain}${region}.${host}`,
  assetsInventoryApiUrl: `${protocol}://${apiSubDomain}${region}.${host}/v1`,
  assetsAttributesApiUrl: `${protocol}://asset-types-api${region}.${host}`,
  workflowsApiUrl: `${protocol}://workflows${region}.${host}`,
  loginUrl: `${protocol}://accounts.${host}/login`,
  tokenRefreshUrl: `${protocol}://accounts.${host}/api/token/refresh`,
  launchpadApiUrl: `${protocol}://apis${region}.${host}/accounts/api`,
  apiProxyUrl: `${apiProxyRegionalUrl}/v1`,
  authyApiUrl: `${protocol}://authy-api${region}.${host}`,
  pendoMonitorScript: `${protocol}://monitor${region}.${host}/v1/monitor.js`,
  getResultsApiUrl: (subdomain: string): string => `${protocol}://${subdomain}.results${region}.${host}/api/v3`,
  highbondDriveUrl: `${protocol}://drive-api${isProductionUS ? '-us' : region}.${host}`,
  isLocal,
  appRegion,
  isGovCloud,
};

export default AppConfig;
