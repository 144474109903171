// Do not edit this file, it is automatically generated
const locales = {
  "de": {
    "translation": {
      "app_name": {
        "asset_inventory": "Anlagenbestand",
        "risk_register": "Risikoregister",
        "default": "Objektbestand"
      },
      "activities_page": {
        "activities": "Aktivitäten",
        "generate_activity_log": "Aktivitätsprotokoll generieren",
        "bubbly_message": "Generieren Sie ein Protokoll mit der Aktivität Ihrer Organisation während des letzten Monats im Anlagenbestand und im Risikoregister.\nWir senden Ihnen eine E-Mail mit einem Link, über den Sie das Aktivitätsprotokoll herunterladen können.\n",
        "bubbly_warning": "Der Link zum Herunterladen läuft 30 Minuten nach Empfang der E-Mail ab.",
        "success_toast_message": "Ihr Export hat begonnen. Wir senden Ihnen eine E-Mail mit einem Link zu Ihrer Aktivitätsprotokolldatei, sobald diese bereit ist.\nBitte beachten Sie, dass der Export eine Weile dauern kann, falls Ihre Datei eine umfangreiche Aktivität enthält.\n",
        "export_activity_log": "Aktivitätsprotokoll exportieren"
      },
      "activity_log": "Aktivität",
      "object_types": {
        "asset": "Anlage",
        "record": "Datensatz"
      },
      "breadcrumbs": "Breadcrumbs",
      "links": {
        "support_url": "https://community.wegalvanize.com/s/contactsupport?language=de",
        "empty_assessments_help_url": "https://help.highbond.com/helpdocs/highbond/de/Default.htm#cshid=pm-asset-workflows"
      },
      "no_results": {
        "heading": "Keine Ergebnisse gefunden",
        "body": "Schränken Sie Ihre Suche bitte ein oder wählen Sie einen anderen Filter."
      },
      "empty_results": {
        "heading": "Hier ist noch nichts!",
        "body": "Fügen Sie {{- recordTypeName}} hinzu, um einen neuen Datensatz für diese/s/n {{- parentName}} zu erstellen."
      },
      "error_notification": {
        "heading": "Ein Problem ist aufgetreten",
        "body": "Ein unbekannter Fehler ist aufgetreten. Aktualisieren Sie bitte die Seite und versuchen Sie es erneut. Wenn das Problem erneut auftritt, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">wenden Sie bitte an unser Support-Team</a>."
      },
      "no_access_notification": {
        "heading": "Kein Zugriff",
        "body": "Sie haben keinen Zugriff auf diese Daten. Wenden Sie sich an einen HighBond-Administrator in Ihrer Organisation, um festzustellen, ob Sie eine Zugangsberechtigung haben sollten."
      },
      "asset_types_page": {
        "page_title": {
          "asset_inventory": "Anlagenbestand - HighBond",
          "risk_register": "Risikoregister - HighBond",
          "default": "Objektbestand - HighBond"
        },
        "heading": {
          "asset_inventory": "Anlagenbestand",
          "risk_register": "Risikoregister",
          "default": "Objektbestand"
        },
        "asset_type": {
          "asset_inventory": "Anlagentyp",
          "default": "Typ"
        },
        "description": "Beschreibung"
      },
      "assets_index_page": {
        "page_title": {
          "asset_inventory": "{{- assetTypeName}} - Anlagenbestand",
          "risk_register": "{{- assetTypeName}} - Risikoregister",
          "default": "{{- assetTypeName}} - Objektbestand"
        },
        "not_configured": {
          "heading": "{{- appTitle}} nicht konfiguriert",
          "message": "{{- appTitle}} ist in Ihrer Organisation nicht konfiguriert. Setzen Sie sich mit Ihrem Systemadministrator in Verbindung."
        }
      },
      "details_page": {
        "heading": "Details zu {{- assetTypeName}}",
        "page_title": {
          "asset_inventory": "{{- assetName}} - Anlagenbestand",
          "risk_register": "{{- assetName}} - Risikoregister",
          "default": "{{- assetName}} - Objektbestand"
        },
        "error": "Ein unbekannter Fehler ist aufgetreten. Aktualisieren Sie bitte die Seite und versuchen Sie es erneut. Wenn das Problem erneut auftritt, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">wenden Sie bitte an unser Support-Team</a>.",
        "workflow_status": {
          "status_transition_errors": "Beim Speichern von {{- name}} ist ein Problem aufgetreten:",
          "status_transition_errors_plural": "Beim Speichern von {{- name}} sind einige Probleme aufgetreten:",
          "updated_by": "von {{userName}}"
        },
        "add_record": "{{- recordTypeName}} hinzufügen",
        "overview": "Übersicht",
        "details": "Einzelheiten",
        "loading": "Wird geladen...",
        "number_of_attributes": "{{attributesCount}} Attribute",
        "number_of_required_attributes": "{{attributesCount}} erforderliche Attribute",
        "questionnaires": {
          "no_results": {
            "heading": "Suchen Sie nach Bewertungen?",
            "body": "Sie können eine Bewertung mit Hilfe der Workflow-Kontrollen am oberen Bildschirmrand starten. <a href='{{supportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">Erfahren Sie mehr</a>"
          },
          "side_panel": {
            "header": "Fragebogen senden",
            "select_questionnaire_label": "Fragebogen",
            "select_questionnaire_placeholder": "Fragebogen auswählen",
            "select_email_label": "Empfänger",
            "contact_instructions": "Geben Sie eine benutzerdefinierte E-Mail-Adresse ein oder wählen Sie einen bestehenden Benutzer aus, der den Fragebogen erhalten soll",
            "invalid_email": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
            "asset_type_validation": {
              "asset_inventory": "Diese Anlage wurde nicht für Assessment-Fragebögen festgelegt. Wenden Sie sich an einen Administrator, um Assessment-Fragebögen mit diesem Anlagentyp zu verknüpfen.",
              "default": "Dieses Objekt wurde nicht für Assessment-Fragebögen eingerichtet. Wenden Sie sich an einen Administrator, um Assessment-Fragebögen mit diesem Typ zu verknüpfen."
            },
            "contact_custom_text": "Als benutzerdefinierte E-Mail-Adresse verwenden",
            "contact_custom_instructions": "Suchen Sie nach einem Benutzer oder geben Sie eine benutzerdefinierte E-Mail-Adresse ein",
            "search_placeholder": "Suchen..."
          }
        },
        "section_header": {
          "additional_information": "Weitere Informationen",
          "number_of_errors": {
            "singular": "{{numberOfErrors}} Fehler",
            "plural": "{{numberOfErrors}} Fehler"
          },
          "required_attributes_missing": "{{numberOfPresentAttributes}} von {{numberOfRequiredAttributes}} erforderlich"
        }
      },
      "actions": {
        "actions": "Aktionen",
        "send": "Senden",
        "apply": "Anwenden",
        "close": "Schließen",
        "save": "Speichern",
        "cancel": "Abbrechen",
        "decline": "Ablehnen",
        "approve": "Genehmigen",
        "save_changes": "Änderungen speichern",
        "discard_changes": "Änderungen verwerfen",
        "add_asset": "{{- assetTypeName}} hinzufügen",
        "delete": "Löschen",
        "delete_asset": {
          "label": "Löschen",
          "heading": "{{- assetName}} löschen?",
          "confirmation": {
            "asset_inventory": "Dadurch werden {{- assetName}} und alle damit verbundenen Arbeiten, einschließlich Anlagenprofil, Bewertungen und zugehörige Datensätze, endgültig gelöscht. Sie werden nicht mehr in der Lage sein, auf diese Elemente zuzugreifen oder sie wiederherzustellen.",
            "default": "Dadurch werden {{- assetName}} und alle damit verbundenen Arbeiten, einschließlich Profil, Bewertungen und zugehörige Datensätze, endgültig gelöscht. Sie werden nicht mehr in der Lage sein, auf diese Elemente zuzugreifen oder sie wiederherzustellen."
          }
        },
        "delete_record": {
          "label": "Löschen",
          "heading": "{{- recordName}} löschen?",
          "confirmation": "Sind Sie sicher, dass Sie {{- recordName}} löschen möchten?  Dadurch werden alle verbundenen Arbeiten, einschließlich der zugehörigen Datensätze, gelöscht. Sie werden nicht mehr in der Lage sein, auf diese Elemente zuzugreifen oder sie wiederherzustellen."
        },
        "download_report": "Bericht herunterladen",
        "download": "Herunterladen",
        "sort": {
          "ascending": "In aufsteigender Reihenfolge sortieren",
          "descending": "In absteigender Reihenfolge sortieren"
        },
        "data_header_menu_trigger": {
          "hide_column": "Spalte ausblenden",
          "direction": {
            "ascending": "aufsteigend",
            "descending": "absteigend"
          },
          "with_direction": "Spalte {{- columnName}}, {{- sortDirection}} sortiert, weitere Aktionen.",
          "without_direction": "Spalte {{- columnName}}, weitere Aktionen.",
          "sort_column_ascending": "{{- columnName}} aufsteigend sortieren.",
          "sort_column_descending": "{{- columnName}} absteigend sortieren."
        },
        "done": "Abgeschlossen"
      },
      "data_types": {
        "boolean": {
          "true": "Richtig",
          "false": "Falsch"
        },
        "datetime": {
          "date": "Datum",
          "time": "Uhrzeit"
        },
        "ad_hoc_relationship": {
          "cancel": "Abbrechen",
          "connection_type": "Verbindungstyp",
          "link": "Verknüpfen",
          "unlink": "Verknüpfung aufheben",
          "search_display_name": "{{displayName}} filtern",
          "add": "{{label}} hinzufügen",
          "target_object_display_title": "ID {{- customerObjectId}} - {{- customerObjectName}}",
          "search_issue": "Suchen...",
          "no_results": "Keine Ergebnisse gefunden"
        },
        "attachment": {
          "add": "{{attributeTypeName}} hinzufügen",
          "remove": "Anhang {{fileName}} entfernen?",
          "general_error": "Die Anhänge konnten nicht geladen werden. Aktualisieren Sie bitte die Seite und versuchen Sie es erneut. Wenn das Problem erneut auftritt, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">wenden Sie bitte an unser Support-Team</a>.",
          "no_access_notification": "Sie haben keinen Zugriff auf diese Anhänge. Wenden Sie sich an einen HighBond-Administrator in Ihrer Organisation, um festzustellen, ob Sie eine Zugangsberechtigung haben sollten.",
          "asset_grid_label": "Dateien",
          "grid": {
            "id": "Name",
            "last_modified": "Hochgeladen",
            "modified_by": "Hochgeladen von",
            "actions": "Aktionen",
            "download_file": "{{fileName}} herunterladen?",
            "file_infected": "Die Datei {{fileName}} kann nicht heruntergeladen werden, weil sie infiziert ist.",
            "file_pending": "Die Datei {{fileName}} kann nicht heruntergeladen werden, weil sie auf Malware gescannt wird.",
            "preparing_download": "Der Download wird vorbereitet",
            "error_getting_download_url": "Diese Datei kann nicht heruntergeladen werden. Bitte versuchen Sie es später erneut oder setzen Sie sich mit Ihrem Systemadministrator in Verbindung, um das Problem zu beheben."
          },
          "loading": "Wird geladen...",
          "add_modal": {
            "heading": "Neuen {{attributeTypeName}} hinzufügen",
            "tooltip_content": "Aus Sicherheitsgründen akzeptiert HighBond keine Dateianhänge mit den folgenden Erweiterungen: .bat, .com und .exe.\n",
            "label": "Neuen {{attributeTypeName}} hochladen",
            "error_message_for_reaching_maximum_number": "Maximale Dateianzahl erreicht. Bitte löschen Sie die bestehende Datei und versuchen Sie es erneut.",
            "error_message_for_reaching_1": "Nur eine Datei kann für diesen Attributtyp hinzugefügt werden. Bitte löschen Sie die bestehende Datei und versuchen Sie es erneut.",
            "error_message_for_invalid_extension": "Die Dateierweiterung für diesen Anhang ist nicht zulässig. Versuchen Sie es mit einer anderen Dateierweiterung.",
            "error_message_for_duplicated_filename": "Diese Datei wurde bereits als Anhang zum Attribut hochgeladen.",
            "error_message_for_general_error": "Anhang kann nicht hinzugefügt werden. Bitte versuchen Sie es später erneut oder setzen Sie sich mit Ihrem Systemadministrator in Verbindung, um das Problem zu beheben."
          }
        }
      },
      "global_notification": {
        "customer_object_created": {
          "success": "{{- customerObjectTypeName}} \"{{- customerObjectName}}\" wurde erfolgreich hinzugefügt."
        },
        "asset_updated": {
          "success": "Sie haben {{- assetTypeName}} erfolgreich aktualisiert!",
          "conflict": {
            "title": "Konflikt bei der Speicherung",
            "description": "Ihr Änderungen stehen im Konflikt mit solchen, die momentan von einem anderen Benutzer vorgenommen werden.\nUm die Änderungen zu speichern, kopieren Sie Ihre Daten, aktualisieren Sie die Seite und übermitteln Sie die Änderungen erneut\n"
          }
        },
        "asset_deleted": {
          "success": "{{- assetTypeName}} \"{{- assetName}}\" wurde erfolgreich gelöscht.",
          "error": "\"{{- assetName}}\" konnte nicht gelöscht werden."
        },
        "go_to": "Gehe zu \"{{- assetName}}\"",
        "record_updated": {
          "success": "Sie haben einen Datensatz erfolgreich aktualisiert!"
        },
        "questionnaire_sent": {
          "success": "Der Fragebogen wurde gesendet."
        }
      },
      "side_navigation": {
        "objects_heading": {
          "asset_inventory": "Anlagen",
          "default": "Objekte"
        },
        "records_heading": "Datensätze"
      },
      "error_page": {
        "page_title": {
          "asset_inventory": "{{heading}} - Anlagenbestand",
          "risk_register": "{{heading}} - Risikoregister",
          "default": "{{heading}} - Objektbestand"
        },
        "page_not_found": {
          "heading": "Die Seite wurde nicht gefunden",
          "body": "Die gesuchte Seite konnte nicht gefunden werden. Sie können zur <a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">Startseite unseres Systems</a> zurückkehren oder <a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">sich an unser Support-Team wenden</a>, um weitere Unterstützung zu erhalten."
        },
        "access_not_allowed": {
          "heading": "Zugriff ist nicht zulässig",
          "body": "Laut unseren Aufzeichnungen haben Sie keine Zugangsberechtigung für diese Seite. Wenden Sie sich an einen HighBond-Administrator in Ihrer Organisation, um festzustellen, ob Sie eine Zugangsberechtigung haben sollten. Alternativ können Sie zur <a href='{{systemHomepage}}' rel=\"noopener noreferrer\" target=\"_blank\">Startseite des Systems</a> zurückkehren oder sich <a href='{{contactSupportTeam}}' rel=\"noopener noreferrer\" target=\"_blank\">an unser Support-Team wenden</a>, um weitere Unterstützung zu erhalten."
        },
        "footer": "Wenn Sie weitere Hilfe benötigen, <a href='{{visitOurSupportPage}}' rel=\"noopener noreferrer\" target=\"_blank\">besuchen Sie unsere Support-Seite</a>, um ein Ticket zu öffnen oder online mit einem Mitarbeiter zu chatten."
      },
      "add_asset_modal": {
        "unsaved_changes_warning": "Sie haben Änderungen vorgenommen, die verloren gehen, wenn Sie dieses Formular schließen.",
        "add_new_item": "Hinzufügen",
        "name_is_required": "Name ist erforderlich.",
        "item_type": "Elementtyp",
        "name": "Name"
      },
      "dirty_form": {
        "title": "Diese/r/s {{- objectTypeName}} hat ungespeicherte Änderungen.",
        "missing_name_error": "Diese/r/s {{- objectTypeName}} kann nicht gespeichert werden, wenn „Name“ leer ist.",
        "description": "Es sieht so aus, als ob Sie dabei waren, Aktualisierungen vorzunehmen. Wenn Sie fortfahren, gehen alle ungespeicherten Änderungen verloren.",
        "save_and_continue": "Speichern und weiter",
        "discard_and_continue": "Ohne Speichern fortfahren",
        "cancel": "Abbrechen"
      },
      "impact_reports_modal": {
        "download_report": {
          "title": "Bericht herunterladen",
          "can_be_downloaded": "Berichte können in den folgenden Formaten heruntergeladen werden.",
          "ready_to_download": "Ihr Bericht steht zum Download bereit."
        },
        "just_a_moment": {
          "title": "Einen Augenblick bitte!",
          "description": "Ihr Bericht wird erstellt. Dies kann in Abhängigkeit von der Größe Ihres Berichts mehrere Minuten dauern. Die Taste Herunterladen wird freigegeben, sobald der Bericht bereit steht."
        },
        "format_selection": {
          "label": "Berichtsformate",
          "instructions": "Wählen Sie ein Berichtsformat"
        },
        "docx_format": "Microsoft Word",
        "xlsx_format": "Microsoft Excel",
        "pptx_format": "Microsoft PowerPoint",
        "pdf_format": "PDF",
        "no_reports": {
          "title": "Keine Berichte gefunden.",
          "description": "Im Moment sind keine Berichte zum Herunterladen verfügbar."
        }
      },
      "errors": {
        "handlers": {
          "required_fields_missing": "{{attributeNameComponent}} ist erforderlich.",
          "required_relationships_missing": {
            "asset_inventory": "{{attributeNameComponent}} muss mit mindestens einer Anlage oder einem Anlage-Datensatz verknüpft sein.",
            "default": "{{attributeNameComponent}} muss mit mindestens einem Objekt verknüpft sein."
          },
          "status_not_found": "Zielstatus ({{statusId}}) befindet sich nicht im Workflow des aktuellen Status.",
          "questionnaire_not_found": "Der Fragebogen befindet sich nicht in den Handlern.",
          "failed_to_update_status": "Der Workflow-Status kann nicht aktualisiert werden. Aktualisieren Sie bitte die Seite und versuchen Sie es erneut. Wenn das Problem erneut auftritt, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">wenden Sie bitte an unser Support-Team</a>.",
          "failed_to_send_questionnaire": "Der Bewertungsfragebogen kann nicht gesendet werden. Aktualisieren Sie bitte die Seite und versuchen Sie es erneut. Wenn das Problem erneut auftritt, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">wenden Sie bitte an unser Support-Team</a>.",
          "failed_to_publish_protobuf": "Die Aktion „notify„ oder „runRobot“ kann nicht ausgeführt werden. Aktualisieren Sie bitte die Seite und versuchen Sie es erneut. Wenn das Problem erneut auftritt, <a href=\"{{support_url}}\" rel=\"noopener noreferrer\" target=\"_blank\">wenden Sie bitte an unser Support-Team</a>."
        },
        "form": {
          "missing_field_value": "Das Feld darf nicht leer sein.",
          "missing_relationship_value": {
            "asset_inventory": "Das Feld muss mit mindestens einer Anlage oder einem Anlage-Datensatz verknüpft sein.",
            "default": "Das Feld muss mit mindestens einem Objekt verknüpft sein."
          },
          "invalid_field_value": "Das Feld enthält einen ungültigen Wert."
        },
        "configuration": {
          "section_missing_attribute_error": "Einige Attribute, die für Sie nicht sichtbar sind, müssen ausgefüllt werden, bevor Sie fortfahren können. Wenden Sie sich bitte an Ihren Administrator, um die Konfiguration dieser/s {{- customerObjectTypeName}} zu überprüfen.\n"
        }
      },
      "metadata": {
        "record_id": "Datensatz-ID",
        "position": "Position",
        "priority": "Priorität",
        "status": "Status",
        "publish_date": "Herausgebungsdatum",
        "publisher": "Herausgeber",
        "assignee": "Empfänger",
        "group": "Gruppe",
        "updated_at": "Aktualisiert am",
        "closed_at": "Geschlossen am",
        "object_type": "Objekttyp",
        "object_id": "Objekt-ID",
        "object_container_type": "Objekt-Container-Typ",
        "object_container_id": "Objekt-Container-ID"
      },
      "protected": {
        "source": "Quelle",
        "user_id": "Benutzer-ID",
        "modified_by": "Geändert von",
        "status_id": "Status-ID",
        "priority_id": "Prioritäts-ID",
        "group_id": "Gruppen-ID",
        "external_source": "Externe Quelle",
        "external_type": "Externer Typ",
        "external_id": "Externe ID",
        "questionnaire_context": "Fragebogen-Kontext"
      }
    }
  }
};
export default locales;